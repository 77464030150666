import { EmbeddedExpandedChatM } from "@/components/embedded/embeddedExpanded.tsx";
import { EmbeddedMinimizeChatM } from "@/components/embedded/embeddedMinimized.tsx";

import { AnimatePresence } from "motion/react";
import { useState } from "react";

// const messages = [
//   { id: 1, message: "Hello, world!", owner: "system" },
//   { id: 2, message: "This is a sample message.", owner: "user" },
//   { id: 3, message: "How are you doing today?", owner: "user" },
//   {
//     id: 4,
//     message: "The quick brown fox jumps over the lazy dog.",
//     owner: "system",
//   },
//   { id: 5, message: "Example data for testing.", owner: "user" },
//   { id: 6, message: "Have a great day!", owner: "system" },
//   { id: 7, message: "This is another example message.", owner: "user" },
//   { id: 8, message: "Feel free to customize these messages.", owner: "system" },
//   { id: 9, message: "JavaScript makes data manipulation easy!", owner: "user" },
//   { id: 10, message: "Good luck with your project!", owner: "system" },
//   {
//     id: 11,
//     message: "JavaScript makes data manipulation easy!",
//     owner: "user",
//   },
//   { id: 12, message: "Good luck with your project!", owner: "system" },
// ];

const motionVariantsExpanded = {
  initial: { opacity: 0, scale: 0.5, x: 50 },
  animate: { opacity: 1, scale: 1, x: 0 },
  exit: { opacity: 0, scale: 0.3, x: 100 },
  transition: { duration: 0.3, ease: "easeInOut" },
};

const motionVariantsMinimized = {
  initial: { opacity: 0, scale: 0.5, y: -50 },
  animate: { opacity: 1, scale: 1, y: 0 },
  exit: { opacity: 0, scale: 3, y: -100 },
  transition: { duration: 0.1, ease: "easeInOut" },
};

function EmbeddedChat() {
  const [isMinimized, setIsMinimized] = useState(true);
  return (
    <AnimatePresence>
      {isMinimized ? (
        <EmbeddedMinimizeChatM
          onClick={() => {
            setIsMinimized((prev) => !prev);
          }}
          key="minimized"
          initial={motionVariantsMinimized.initial}
          animate={motionVariantsMinimized.animate}
          exit={motionVariantsMinimized.exit}
          transition={motionVariantsMinimized.transition}
        />
      ) : (
        <EmbeddedExpandedChatM
          setIsMinimized={setIsMinimized}
          key="expanded"
          initial={motionVariantsExpanded.initial}
          animate={motionVariantsExpanded.animate}
          exit={motionVariantsExpanded.exit}
          transition={motionVariantsExpanded.transition}
        />
      )}
    </AnimatePresence>
  );
}

export { EmbeddedChat };
