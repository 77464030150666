import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Ellipsis = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      className={className}
    >
      <path
        d="M12.75 8.5C13.85 8.5 14.75 7.6 14.75 6.5C14.75 5.4 13.85 4.5 12.75 4.5C11.65 4.5 10.75 5.4 10.75 6.5C10.75 7.6 11.65 8.5 12.75 8.5ZM12.75 10.5C11.65 10.5 10.75 11.4 10.75 12.5C10.75 13.6 11.65 14.5 12.75 14.5C13.85 14.5 14.75 13.6 14.75 12.5C14.75 11.4 13.85 10.5 12.75 10.5ZM12.75 16.5C11.65 16.5 10.75 17.4 10.75 18.5C10.75 19.6 11.65 20.5 12.75 20.5C13.85 20.5 14.75 19.6 14.75 18.5C14.75 17.4 13.85 16.5 12.75 16.5Z"
        fill="#9D9D9D"
      />
    </svg>
  );
};
