import { cn } from "@/utils/cn";

type EllipseProps = React.HTMLAttributes<HTMLDivElement>;

const Ellipse = ({ className }: EllipseProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="5"
      height="6"
      viewBox="0 0 5 6"
      fill="currentColor"
      className={className}
    >
      <circle
        cx="2.5"
        cy="2.5"
        r="2.5"
        transform="matrix(-1 0 0 1 5 0.655762)"
        fill="currentColor"
      />
    </svg>
  );
};

type EllipsisPaginationProps = React.HTMLAttributes<HTMLDivElement> & {
  index: number;
  size: number;
};

export const EllipsisPagination = ({
  index,
  size,
  className,
}: EllipsisPaginationProps) => {
  return (
    <div
      className={cn("flex items-center justify-center w-full h-fit", className)}
    >
      <div className="flex flex-row self-center gap-2 my-1 justify-self-center">
        {Array.from({ length: size }).map((_, i) =>
          index === i + 1 ? (
            <Ellipse key={i} className="text-black" />
          ) : (
            <Ellipse key={i} className="text-white" />
          )
        )}
      </div>
    </div>
  );
};
