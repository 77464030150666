import { useSidebar } from "@/hooks/useSidebar";
import { Button } from "../ui/button";
import { X } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSession } from "@/hooks/useSession";
import { cn } from "@/utils/cn";
import { TSession } from "@/contexts/sessionContext";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../ui/alert-dialog";
import { Trash } from "../icons/trash";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import { useState, useRef, forwardRef } from "react";
import { toast } from "sonner";
import { Input } from "../ui/input";
import { Pencil } from "../icons/pencil";
import { NewChat } from "../icons/newChat";

type ChatHistoryProps = {
  className: string;
};

export const ChatHistoryDesktop = forwardRef<HTMLDivElement, ChatHistoryProps>(
  ({ className }, ref) => {
    const navigate = useNavigate();
    const { sessionList } = useSession();
    const { setSidebarOpen } = useSidebar();

    const handleNewChat = () => {
      setSidebarOpen(false);
      navigate("/");
    };

    const groupedSessions: Record<string, TSession[]> = sessionList.reduce(
      (acc, session) => {
        if (!acc[session.sessionDate]) {
          acc[session.sessionDate] = [];
        }
        acc[session.sessionDate].push(session);
        return acc;
      },
      {} as Record<string, TSession[]>,
    );

    const sessionsByDate: TSession[][] = Object.values(groupedSessions);

    return (
      <div className={className} ref={ref}>
        <div className="max-h-chat-history bg-history-glass border-muted shadow-chat-history relative bottom-10 z-20 flex h-96 flex-col overflow-hidden rounded-3xl border">
          <div className="flex flex-grow flex-col overflow-y-auto bg-transparent px-2">
            <h2 className="mb-2 mt-6 self-start text-nowrap pl-2 text-3xl font-light">
              Our History
            </h2>
            <div className="h-full max-h-full list-none space-y-5 pr-1 pt-4">
              {sessionsByDate
                .slice()
                .reverse()
                .map((sessionGroup) => (
                  <SessionGroup
                    sessionGroup={sessionGroup.slice().reverse()}
                    key={sessionGroup[0].sessionDate}
                  />
                ))}
            </div>
          </div>
          <div className="border-muted bg-primary-dark align-center flex flex-row justify-center space-x-1 border-t py-3">
            <Button
              size="icon"
              variant="ghost"
              title="Close History"
              onClick={() => setSidebarOpen(false)}
            >
              <X className="h-full w-full" />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              title="New Conversation"
              onClick={handleNewChat}
            >
              <NewChat className="text-white" />
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

const SessionGroup = ({ sessionGroup }: { sessionGroup: TSession[] }) => {
  return (
    <div className="flex flex-col space-y-1">
      <p className="px-4 text-left text-[#9D9D9D]">
        {sessionGroup[0].sessionDate}
      </p>
      {sessionGroup.map((session) => (
        <HistoryItem
          session={session}
          key={session.sessionId}
          className="relative flex w-full flex-row items-center justify-end rounded-lg px-5"
        />
      ))}
    </div>
  );
};

interface HistoryItem {
  session: TSession;
  className?: string;
}

const HistoryItem = ({ session, className }: HistoryItem) => {
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionId, editName, remove } = useSession();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState<string>(session.sessionName);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setMobileSidebarOpen, setSidebarOpen } = useSidebar();

  function handleNameChange() {
    if (!inputRef.current) return;
    setEditedName(inputRef.current.value);
  }

  function handleNameSubmit() {
    editName(session.sessionId, editedName);
    setIsEditingName(false);
  }

  function handleClickSession() {
    if (sessionId === session.sessionId) return;
    queryClient.invalidateQueries({
      queryKey: ["messages", sessionId],
      refetchType: "none",
    });
    setMobileSidebarOpen(false);
    setSidebarOpen(false);
    navigate(`/s/${session.sessionId}`);
  }

  return (
    <div
      className={cn(
        "transition-all duration-300 hover:bg-white/25 data-[active=true]:bg-white/25",
        className,
      )}
      data-active={session.sessionId === sessionId}
    >
      {isEditingName ? (
        <div className="mr-auto flex h-full w-full min-w-0 flex-col space-y-1 p-0 text-left hover:bg-transparent">
          <Input
            type="text"
            value={editedName}
            onChange={handleNameChange}
            onBlur={handleNameSubmit}
            onKeyDown={(e) => e.key === "Enter" && handleNameSubmit()}
            className="relative right-1 my-0 h-fit w-full px-1 py-2 text-base font-medium"
            ref={inputRef}
            autoFocus
          />
        </div>
      ) : (
        <Button
          variant={"ghost"}
          key={session.sessionId}
          disabled={!!isMutating}
          onClick={handleClickSession}
          className="mr-auto flex h-full w-full min-w-0 flex-col p-0 py-2.5 text-left hover:bg-transparent"
        >
          <p className="w-full overflow-hidden text-ellipsis whitespace-nowrap text-base">
            {session.sessionName}
          </p>
        </Button>
      )}
      <Button
        variant="ghost"
        size="icon"
        title="Edit Name"
        className="h-min rounded-lg py-1.5 text-xs"
        onClick={() => setIsEditingName(true)}
      >
        <Pencil />
      </Button>
      <AlertDialog>
        <AlertDialogTrigger asChild={true}>
          <Button
            variant={"ghost"}
            size="icon"
            title="Delete Conversation"
            className="text-red flex h-min flex-row py-1.5 text-xs"
          >
            <Trash />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className="bg-primary-dark max-w-[80%] overflow-hidden rounded-md border-white p-0 pb-2 text-white sm:max-w-lg">
          <AlertDialogHeader>
            <AlertDialogTitle className="bg-inherit px-6 py-2 text-white">
              <div>Are you sure? </div>
            </AlertDialogTitle>
            <AlertDialogDescription className="px-6 pt-6 text-left">
              This action cannot be undone. This will permanently delete this
              conversation.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="px-6">
            <AlertDialogCancel className="rounded-xl border-none">
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                remove(session.sessionId);
                toast.success("Conversation has been deleted");
              }}
              className="text-red hover:bg-red rounded-xl bg-transparent font-semibold hover:text-white"
            >
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
          º
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};
