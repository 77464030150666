import { useNavigate, useParams } from "react-router-dom";
import { InputField } from "../components/inputField";
import { Messages } from "../components/messages";
import { Suggestions } from "../components/suggestions";
import { useChat } from "@/hooks/useChat";
import { Toaster } from "@/components/ui/sonner-toast";
import { cn } from "@/utils/cn";
import { useSession } from "@/hooks/useSession";
import { useSidebar } from "@/hooks/useSidebar";
import { ChatHistoryDesktop } from "@/components/chatHistory/chatHistoryDesktop";
import { AnimatePresence, motion } from "motion/react";
import { motionConfig } from "@/configs/motion";

const MSuggestions = motion.create(Suggestions, { forwardMotionProps: true });
const MInputField = motion.create(InputField, { forwardMotionProps: true });
const MChatHistoryDesktop = motion.create(ChatHistoryDesktop, {
  forwardMotionProps: true,
});

const Chat = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();
  const { isSidebarOpen } = useSidebar();
  const { updateSessionList } = useSession();
  const { messages, streamMessage, isLoading } = useChat(
    sessionId as string,
    "localStorage",
  );

  // Creates and returns a new sessionId if it does not exist (if user is on the home page)
  const createSessionId = (sessionId: string | undefined) => {
    if (!sessionId) {
      // Generate a new sessionId if it doesn't exist and add it to the session list
      const newSessionId = crypto.randomUUID();
      updateSessionList(newSessionId);
      navigate(`/s/${newSessionId}`);
      return newSessionId;
    } else {
      return sessionId;
    }
  };

  // Send message to API endpoint and update history with response
  async function handleSendMessage(text: string) {
    if (!text) return;
    const _sessionId = createSessionId(sessionId);
    streamMessage({
      userMessage: text,
      sessionId: _sessionId,
    });
  }

  return (
    <>
      <div
        className={cn(
          messages.length
            ? "to-blue bg-gradient-to-b from-[#080b16]"
            : "from-blue to-blue md:bg-desktop bg-gradient-to-b",
          "transition-bg flex flex-col overflow-hidden md:flex-row",
        )}
      >
        {!messages.length && (
          <>
            {/* blurred dots */}
            <div className="bg-blur-white animate-move-down fixed -right-24 top-20 size-40 rounded-full opacity-60 blur-[50px] md:-right-[600px] md:top-0 md:size-[800px] md:animate-none md:opacity-40 md:blur-[80px]" />
            <div className="bg-blur-red animate-move-right fixed -left-16 top-[calc(50%-9rem)] size-40 rounded-full opacity-80 blur-[40px] md:-left-[600px] md:top-0 md:size-[800px] md:animate-none md:opacity-60 md:blur-[60px]" />
          </>
        )}
        <div className="relative flex h-dvh w-full flex-col items-center text-white">
          {messages.length > 0 ? (
            <Messages
              key="messages"
              messages={messages}
              isLoading={isLoading}
            />
          ) : (
            <MSuggestions
              key="suggestions"
              handleSuggestionClick={handleSendMessage}
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2 }}
            />
          )}
          <div className="absolute bottom-0 z-10 flex w-full justify-center bg-transparent bg-none px-3">
            {/* bottom fade */}
            <div className="h-bottom-fade bg-bottom-fade pointer-events-none absolute bottom-0 left-0 z-10 flex w-full flex-row items-center justify-center" />
            <AnimatePresence mode="sync">
              {isSidebarOpen ? (
                <MChatHistoryDesktop
                  className="absolute bottom-0 z-50 min-h-16 w-full max-w-md bg-transparent px-4"
                  key="ChatHistoryDesktop"
                  initial={motionConfig.shrinkInitial}
                  animate={motionConfig.shrinkAnimate}
                  exit={motionConfig.shrinkExit}
                  style={{
                    originY: "80%", // animate from bottom
                  }}
                />
              ) : (
                <MInputField
                  handleSendMessage={handleSendMessage}
                  isPending={isLoading}
                  showHomeButton={!!messages.length}
                  key="InputField"
                  initial={motionConfig.shrinkInitial}
                  animate={motionConfig.shrinkAnimate}
                  exit={motionConfig.shrinkExit}
                />
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <Toaster
        className="pointer-events-auto"
        position="top-center"
        toastOptions={{ className: "bg-[#142645]" }}
      />
    </>
  );
};

export { Chat };
