import { useState } from "react";

type UseSessionStorageType<T> = {
  key: string;
  initialValue: T;
};

function useSessionStorage<T>({ key, initialValue }: UseSessionStorageType<T>) {
  const getValue = () => {
    const storedValue = sessionStorage.getItem(key);
    try {
      return !storedValue
        ? JSON.parse(storedValue!).catch(() => initialValue)
        : initialValue;
    } catch (error) {
      console.error("Error session storage value: ", error);
      return initialValue;
    }
  };

  const [sessionValue, setSessionValue] = useState(getValue);

  const setValue = (value: unknown) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
      setSessionValue(value);
    } catch (error) {
      console.error("Error setting session storage value:", error);
    }
  };

  const removeValue = () => {
    try {
      sessionStorage.removeItem(key);
      setSessionValue(initialValue);
    } catch (error) {
      console.error("Error removing session storage value:", error);
    }
  };

  return [sessionValue, setValue, removeValue, key] as const;
}

export { useSessionStorage };
