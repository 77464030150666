import { useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Popover } from "@/components/ui/popover";
import { PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { useNavigate } from "react-router-dom";
import { useIsMutating, useQueryClient } from "@tanstack/react-query";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { toast } from "sonner";
import { useSession } from "@/hooks/useSession";
import { Trash } from "../icons/trash";
import { Pencil } from "../icons/pencil";
import { Ellipsis } from "../icons/ellipsis";
import { cn } from "@/utils/cn";
import { TSession } from "@/contexts/sessionContext";
import { useSidebar } from "@/hooks/useSidebar";

type ChatHistoryMobileContentProps = React.HTMLAttributes<HTMLUListElement>;

export const ChatHistoryMobileContent = ({
  className,
}: ChatHistoryMobileContentProps) => {
  const { sessionList } = useSession();

  return (
    <>
      <h2 className="self-start pl-2 mt-6 mb-3 text-3xl font-light text-nowrap">
        Our History
      </h2>
      <ul
        className={cn(
          "h-full max-h-full overflow-y-auto list-none space-y-3 pt-4 pr-1",
          className,
        )}
      >
        {sessionList
          .slice()
          .reverse()
          .map((session) => (
            <HistoryItem session={session} key={session.sessionId} />
          ))}
      </ul>
      <div
        // faded overlay on bottom
        className="absolute bottom-0 left-0 z-10 flex flex-row items-center justify-center w-full h-1/6"
        style={{
          background:
            "linear-gradient(180deg, rgba(9, 31, 68, 0.00) 0%, #13284C 53%, #091F44 100%)",
        }}
      />
    </>
  );
};

interface HistoryItemProps {
  session: TSession;
}

const HistoryItem = ({ session }: HistoryItemProps) => {
  const isMutating = useIsMutating();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { sessionId, editName, remove } = useSession();
  const [isEditingName, setIsEditingName] = useState(false);
  const [editedName, setEditedName] = useState<string>(session.sessionName);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setMobileSidebarOpen, setSidebarOpen } = useSidebar();

  function handleNameChange() {
    if (!inputRef.current) return;
    setEditedName(inputRef.current.value);
  }

  function handleNameSubmit() {
    editName(session.sessionId, editedName);
    setIsEditingName(false);
  }

  function handleClickSession() {
    if (sessionId === session.sessionId) return;
    queryClient.invalidateQueries({
      queryKey: ["messages", sessionId],
      refetchType: "none",
    });
    setMobileSidebarOpen(false);
    setSidebarOpen(false);
    navigate(`/s/${session.sessionId}`);
  }

  return (
    <li
      className="w-full relative flex flex-row py-3 px-5 items-center justify-end rounded-[14px] bg-primary-dark border border-[rgba(43,62,94,0.50)] data-[active=true]:bg-[#4e5e6c]/60 hover:bg-[#4e5e6c]/50"
      data-active={session.sessionId === sessionId}
    >
      {isEditingName ? (
        <div className="flex flex-col w-full h-full min-w-0 p-0 mr-auto space-y-1 text-left hover:bg-transparent">
          <p className="w-full text-[#9D9D9D] text-sm font-medium">
            {session.sessionDate}
          </p>
          <Input
            type="text"
            value={editedName}
            onChange={handleNameChange}
            onBlur={handleNameSubmit}
            onKeyDown={(e) => e.key === "Enter" && handleNameSubmit()}
            className="relative w-full px-1 py-0.5 text-base right-1 h-fit font-medium my-0"
            ref={inputRef}
            autoFocus
          />
        </div>
      ) : (
        <Button
          variant={"ghost"}
          key={session.sessionId}
          disabled={!!isMutating}
          onClick={handleClickSession}
          className="flex flex-col w-full h-full min-w-0 p-0 mr-auto text-left hover:bg-transparent"
        >
          <p className="w-full text-[#9D9D9D] text-sm font-medium">
            {session.sessionDate}
          </p>
          <p className="w-full overflow-hidden text-base whitespace-nowrap text-ellipsis">
            {session.sessionName}
          </p>
        </Button>
      )}
      <Popover>
        <PopoverTrigger asChild>
          <Button variant={"ghost"} className="h-full p-2 hover:bg-transparent">
            <Ellipsis className="size-6" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="flex w-full p-2 px-1 text-white rounded-lg bg-blue border-white/10"
          align="end"
          role="menu"
        >
          <div className="flex flex-col rounded-md">
            <Button
              variant="ghost"
              size="sm"
              className="justify-start py-1.5 text-xs rounded-lg  h-min"
              onClick={() => setIsEditingName(true)}
            >
              <Pencil className="mr-2 text-white size-4" />
              Edit
            </Button>
            <AlertDialog>
              <AlertDialogTrigger asChild={true}>
                <Button
                  variant={"ghost"}
                  size="sm"
                  className="flex flex-row items-center justify-start py-1.5 text-xs text-red h-min"
                >
                  <Trash className="mr-2 size-4" />
                  <strong>Delete</strong>
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent className="p-0 pb-2 rounded-md bg-primary-dark overflow-hidden text-white max-w-[80%] sm:max-w-lg ">
                <AlertDialogHeader>
                  <AlertDialogTitle className="px-6 py-2 pt-4 text-white bg-inherit">
                    <div>Are you sure? </div>
                  </AlertDialogTitle>
                  <AlertDialogDescription className="px-6 pt-6 text-left">
                    This action cannot be undone. This will permanently delete
                    this conversation.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter className="px-6">
                  <AlertDialogCancel className="border-none rounded-xl">
                    Cancel
                  </AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => {
                      remove(session.sessionId);
                      toast.success("Conversation has been deleted");
                    }}
                    className="bg-transparent rounded-xl text-red font-semibold hover:bg-red hover:text-white"
                  >
                    Continue
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </PopoverContent>
      </Popover>
    </li>
  );
};
