import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const Clock = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="65"
      height="66"
      viewBox="0 0 65 66"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.0239 65.5212C48.6991 65.5212 64.6489 49.5714 64.6489 29.8962C64.6489 10.2211 48.6991 -5.72876 29.0239 -5.72876C9.34878 -5.72876 -6.60107 10.2211 -6.60107 29.8962C-6.60107 49.5714 9.34878 65.5212 29.0239 65.5212ZM31.8739 8.1255C31.8739 6.55149 30.5979 5.2755 29.0239 5.2755C27.4499 5.2755 26.1739 6.55149 26.1739 8.1255V29.6588C26.1739 31.364 27.5563 32.7463 29.2614 32.7463H42.8781C44.4521 32.7463 45.7281 31.4703 45.7281 29.8963C45.7281 28.3223 44.4521 27.0463 42.8781 27.0463H31.8739V8.1255Z"
        fill="currentColor"
      />
    </svg>
  );
};
