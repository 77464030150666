import { useRef } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { SendIcon } from "../icons/embedded/sendIcon.tsx";

const inputFieldConfig = {
  minHeight: "30",
  maxHeight: "112",
};

export function EmbeddedInputField({
  handleSendMessage,
  isPending,
}: {
  handleSendMessage: (text: string) => Promise<void>;
  isPending: boolean;
}) {
  const textAreaElement = useRef<HTMLTextAreaElement>(null);

  const textAreaHeightAdjust = () => {
    if (!textAreaElement.current) {
      return;
    }

    if (!textAreaElement.current.textLength) {
      textAreaElement.current.style.height = `${inputFieldConfig.minHeight}px`;
      return;
    }

    textAreaElement.current.style.height = `${Math.max(
      textAreaElement.current.scrollHeight,
      30,
    )}px`;
  };

  function handleClick() {
    if (!textAreaElement.current) return;

    const text = textAreaElement.current.value.trim();
    if (text.length < 1) return;
    handleSendMessage(text);

    // Resize text box
    textAreaElement.current.value = "";
    textAreaElement.current.style.height = `${inputFieldConfig.minHeight}px`;
  }

  return (
    <div
      id="inputfield"
      className="s:rounded-lg absolute bottom-0 z-10 flex w-full justify-center bg-inherit px-5"
    >
      <div className="flex h-auto w-full max-w-full flex-row items-center justify-between gap-3 rounded-2xl bg-white py-3">
        <Textarea
          id="textInput"
          autoFocus
          placeholder="How can I help?"
          className="pb-auto border-gray scroll-bar-hidden no-scrollbar shadow-input ring-blue row-auto h-8 max-h-28 min-h-8 flex-grow resize-none overflow-scroll scroll-smooth rounded-lg border bg-inherit align-middle text-xs text-black outline-none"
          ref={textAreaElement}
          onChange={textAreaHeightAdjust}
          onKeyUp={(e) => {
            if (isPending) return;
            e.key === "Enter" && !e.shiftKey && handleClick();
          }}
        ></Textarea>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              id="submit"
              size="sm"
              onClick={handleClick}
              disabled={isPending}
              className="text-2xs text-blue bg-blue/90 hover:bg-blue ring-blue ml-auto rounded-full ring-offset-1"
            >
              <SendIcon></SendIcon>
            </Button>
          </TooltipTrigger>
          <TooltipContent className="bg-blue border-gray shadow-input">
            <p>Send</p>
          </TooltipContent>
        </Tooltip>
      </div>
    </div>
  );
}
