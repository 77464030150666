import { useNavigate, useParams } from "react-router-dom";
import { createContext } from "react";
import { useLocalStorage } from "@/hooks/useLocalStorage";

export type TSession = {
  sessionId: string;
  sessionName: string;
  sessionDate: string;
};

type TSessionContext = {
  sessionId: string | undefined;
  sessionList: TSession[];
  addCurrentSession: () => void;
  updateSessionList: (id: string) => void;
  remove: (id: string) => void;
  editName: (id: string, newName: string) => void;
};

export const SessionContext = createContext<TSessionContext | null>(null);

const sessionStorageConfig = {
  key: `chat-history`,
  initialValue: [],
};

export const SessionProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const [sessionList, setSessionList] =
    useLocalStorage<TSession[]>(sessionStorageConfig);

  /** Add current session to session history */
  const addCurrentSession = () => {
    if (!sessionId) return;

    // First check if it already exists
    if (!sessionList.find((session) => session.sessionId === sessionId)) {
      setSessionList((prev) => {
        const updatedList = [
          ...prev,
          {
            sessionId: sessionId,
            sessionName: "New Chat",
            sessionDate: new Date().toLocaleDateString("en-US"),
          },
        ];
        return updatedList;
      });
    }
  };

  /** Add new session to session history */
  const updateSessionList = (id: string) => {
    if (!sessionList.find((session) => session.sessionId === id)) {
      setSessionList((prev) => {
        const updatedList = [
          ...prev,
          {
            sessionId: id,
            sessionName: "New Chat",
            sessionDate: new Date().toLocaleDateString("en-US"),
          },
        ];
        return updatedList;
      });
    }
  };

  /** Remove a session from the session history */
  const remove = (id: string) => {
    if (!id) return;

    setSessionList((prev) => {
      const updatedList = prev.filter((session) => session.sessionId !== id);
      return updatedList;
    });
    localStorage.removeItem(id); // Remove the messages as well

    // If the session being removed is the current session, navigate to the home page
    if (id === sessionId) {
      navigate("/");
    }
  };

  /** Edit the display name of a session in the session list */
  const editName = (id: string, newName: string) => {
    if (!id || !newName.trim()) return;

    setSessionList((prev) => {
      const updatedList = prev.map((session) =>
        session.sessionId === id
          ? { ...session, sessionName: newName.trim() }
          : session
      );
      return updatedList;
    });
  };

  return (
    <SessionContext.Provider
      value={{
        sessionId,
        sessionList,
        addCurrentSession,
        updateSessionList,
        remove,
        editName,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
