import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const SendIcon = ({ className }: Props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      className={className}
    >
      <g id="send 2" clipPath="url(#clip0_2894_1166)">
        <path
          id="Vector"
          d="M12.8332 1.1665L6.41656 7.58317"
          stroke="white"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M12.8332 1.1665L8.7499 12.8332L6.41657 7.58317L1.16656 5.24984L12.8332 1.1665Z"
          stroke="white"
          strokeWidth="1.16667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2894_1166">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
