import { memo, useEffect, useRef, useState } from "react";
import Markdown from "@/components/ui/markdown";
import remarkBreaks from "remark-breaks";
import { TChatMessage } from "@/types/message";
import { ErrorMessage } from "@/components/ui/errorMessage";
import { Map } from "@/components/map/map";
import { LoadingText } from "../loadingText";
import { useIsMutating } from "@tanstack/react-query";
import { cn, debounce } from "@/utils";
import { useAutoScroll } from "@/hooks/useAutoScroll";
import { Button } from "../ui/button";
import { useParams } from "react-router-dom";
import { ArrowDown } from "lucide-react";
import { motion } from "motion/react";

const EmbeddedMessages = memo(
  ({
    messages,
    isLoading,
  }: {
    messages: TChatMessage[];
    isLoading: boolean;
  }) => {
    const { sessionId } = useParams();
    const isMutating = useIsMutating({ mutationKey: ["messages"] });
    const scrollContainer = useRef<HTMLDivElement>(null);
    const [scrollButtonToggled, setScrollButtonToggled] = useState(false);
    useAutoScroll(scrollContainer);

    // Hacky way of hiding scroll button when navigating to different conversation
    useEffect(() => {
      setScrollButtonToggled(false);
    }, [sessionId]);

    const handleScroll = debounce(() => {
      if (!scrollContainer.current) return;
      // Show button when user scrolls 25 pixels from the bottom of the chat
      if (
        scrollContainer.current.scrollTop <
        scrollContainer.current.scrollHeight -
          scrollContainer.current.clientHeight -
          25
      ) {
        setScrollButtonToggled(true);
      } else {
        setScrollButtonToggled(false);
      }
    }, 50);

    return (
      <motion.div
        className="no-scrollbar mb-auto flex h-full w-full max-w-full flex-col items-start space-y-3 overflow-y-auto overflow-x-hidden pb-8 text-sm"
        onScroll={handleScroll}
        ref={scrollContainer}
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.2 }}
      >
        {messages.map((message, index) => {
          // User Message
          return message.owner === "user" ? (
            <div className="flex w-full place-content-center" key={message.id}>
              <div className="max-w-chat flex w-full justify-end p-1">
                <div className="bg-blue rounded-message shadow-input flex max-w-[90%] flex-col self-end whitespace-pre-wrap text-wrap break-words border border-[rgba(43,62,94,0.50)] p-4 py-1.5 text-left text-xs text-white">
                  <p>{message.message}</p>
                </div>
              </div>
            </div>
          ) : (
            // Bot Message
            <div className="flex w-full place-content-center" key={message.id}>
              <div className="max-w-chat flex w-full flex-row self-start p-1">
                <div className="shadow-input border-gray flex min-w-0 flex-col text-wrap break-words rounded-br-lg rounded-tl-lg rounded-tr-lg border p-4 py-1.5 text-left">
                  {message.error ? (
                    <ErrorMessage>An error occured</ErrorMessage>
                  ) : (
                    message.message && (
                      <Markdown
                        className={cn(
                          "prose prose-invert max-w-full",
                          !!isMutating &&
                            index === messages.length - 1 &&
                            "loading-dot",
                          "text-xs text-black",
                        )}
                        children={message.message}
                        remarkPlugins={[remarkBreaks]}
                      />
                      /* prose class from tailwindcss-typography allows markdown rendering when using tailwind */
                    )
                  )}
                  {message.directions?.destination &&
                    Object.keys(message.directions.destination).length > 0 && (
                      <Map
                        destinationAddress={message.directions?.destination}
                        originAddress={message.directions?.origin}
                      />
                    )}
                </div>
              </div>
            </div>
          );
        })}
        {isLoading && messages.length % 2 !== 0 && (
          <LoadingText isLoading={isLoading} />
        )}
        <Button
          className={`${
            scrollButtonToggled && sessionId ? "inline-flex" : "hidden"
          } bg-blue hover:bg-blue absolute bottom-36 right-1/2 z-10 -mr-2 size-7 rounded-full outline outline-1 outline-white/30`}
          size={"icon"}
          onClick={() => {
            setScrollButtonToggled(false);
            if (scrollContainer.current) {
              scrollContainer.current.scrollTo({
                top:
                  scrollContainer.current.scrollHeight -
                  scrollContainer.current.clientHeight,
                behavior: "smooth",
              });
            }
          }}
        >
          <ArrowDown className="size-4 text-white" />
        </Button>
      </motion.div>
    );
  },
);

export { EmbeddedMessages };
