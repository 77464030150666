import { Button } from "@/components/ui/button";
import { cn } from "@/utils/cn";

const suggestions = [
  "How do I connect to the WiFi?",
  "Tell me about Bulldog Bound.",
  "Where is the Financial Aid Office located?",
  "Places on campus to eat.",
];

export function EmbeddedSuggestions({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => Promise<void>;
}) {
  return (
    <div className="mx-[1px] mb-auto flex h-full w-full max-w-full flex-col items-end space-y-2 overflow-y-auto overflow-x-hidden pb-8">
      <div className="flex w-full flex-row flex-wrap"></div>
      {suggestions.map((s, i) => (
        <SuggestionPrompt
          className="hover:bg-blue focus-within:bg-blue group flex flex-row justify-start"
          key={i + s}
          prompt={s}
          handleSuggestiOnClick={handleSuggestionClick}
        >
          <p className="line-clamp-2 h-fit w-fit text-start text-xs text-black group-focus-within:text-white group-hover:text-white">
            {s}
          </p>
        </SuggestionPrompt>
      ))}
    </div>
  );
}

type SuggestionPromptProps = {
  handleSuggestiOnClick: (message: string) => void;
  prompt: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export function SuggestionPrompt({
  className,
  children,
  prompt,
  handleSuggestiOnClick,
  ...props
}: SuggestionPromptProps) {
  return (
    <Button
      className={cn(
        "flex-justify-end border-gray shadow-input ring-blue relative flex h-fit w-[200px] overflow-hidden text-wrap rounded-lg border bg-white px-3 py-1 text-black",
        className,
      )}
      onClick={() => handleSuggestiOnClick(prompt)}
      {...props}
    >
      {children}
    </Button>
  );
}
