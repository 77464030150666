import { motion, MotionProps } from "motion/react";
import { ButtonHTMLAttributes, forwardRef } from "react";
import { Button } from "../ui/button.tsx";
import chatIcon from "@/assets/embedded/chat-icon.svg";

type EmbeddedMinimizeChatProps = ButtonHTMLAttributes<HTMLButtonElement> &
  MotionProps;
const EmbeddedMinimizeChat = forwardRef(
  (props: EmbeddedMinimizeChatProps, ref: React.Ref<HTMLButtonElement>) => {
    return (
      <Button
        ref={ref}
        {...props}
        className="hover:bg-red-dark bg-red fixed bottom-7 right-7 z-[100000] h-fit w-fit rounded-full p-4 shadow-md transition duration-200 hover:cursor-pointer hover:ease-in"
      >
        <img src={chatIcon} className="h-9 w-9" />
      </Button>
    );
  },
);

const EmbeddedMinimizeChatM = motion.create(EmbeddedMinimizeChat, {
  forwardMotionProps: true,
});

export { EmbeddedMinimizeChatM };
