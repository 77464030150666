import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const ForkAndPlate = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="64"
      viewBox="0 0 72 64"
      fill="none"
      className={className}
    >
      <g clipPath="url(#clip0_2848_49)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9662 2.05831C11.9662 -0.127815 13.7384 -1.90002 15.9245 -1.90002C18.1106 -1.90002 19.8828 -0.127815 19.8828 2.05831V20.009C19.8828 21.1277 20.7896 22.0343 21.9081 22.0343C23.0267 22.0343 23.9333 21.1277 23.9333 20.009V2.05831C23.9333 -0.127815 25.7055 -1.90002 27.8917 -1.90002C30.0778 -1.90002 31.85 -0.127815 31.85 2.05831V20.009C31.85 25.4999 27.3989 29.9509 21.9081 29.9509C21.2141 29.9509 20.5368 29.8799 19.8828 29.7446V57.9052C19.8828 60.0913 18.1106 61.8635 15.9245 61.8635C13.7384 61.8635 11.9662 60.0913 11.9662 57.9052V29.7446C11.3123 29.8799 10.6349 29.9509 9.94095 29.9509C4.45017 29.9509 -0.000976562 25.4999 -0.000976562 20.009V2.05831C-0.000976562 -0.127815 1.77123 -1.90002 3.95736 -1.90002C6.14348 -1.90002 7.91569 -0.127815 7.91569 2.05831V20.009C7.91569 21.1277 8.82243 22.0343 9.94095 22.0343C11.0595 22.0343 11.9662 21.1277 11.9662 20.009V2.05831Z"
          fill="currentColor"
        />
        <path
          d="M39.3177 6.99738C42.989 6.99738 46.611 7.84191 49.9036 9.46567C53.1963 11.0894 56.0711 13.4488 58.3062 16.3614C60.5411 19.2741 62.0759 22.6618 62.7922 26.2624C63.5084 29.8631 63.3868 33.5803 62.4365 37.1264C61.4863 40.6725 59.7331 43.9525 57.3125 46.7127C54.8919 49.473 51.8688 51.6393 48.477 53.0442C45.0852 54.4491 41.4157 55.055 37.7523 54.8148C34.0889 54.5748 30.5299 53.4951 27.3506 51.6596"
          stroke="currentColor"
          strokeWidth="7.91667"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2848_49">
          <rect
            width="71.25"
            height="64.9167"
            fill="white"
            transform="translate(-0.000488281 -1.90002)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
