import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const History = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      className={className}
    >
      <path
        d="M19.1018 5.22559C12.1903 5.22559 6.52538 10.5697 6.01435 17.3506C5.97284 17.9013 5.52909 18.3506 4.97681 18.3506H4.01602C3.12512 18.3506 2.67895 19.4277 3.30891 20.0577L7.2578 24.0066C7.26901 24.0178 7.27834 24.0307 7.28543 24.0449C7.33041 24.1349 7.45026 24.1544 7.52147 24.0834L11.5563 20.0586C12.1874 19.429 11.7415 18.3506 10.85 18.3506H9.89347C9.34119 18.3506 8.88834 17.9015 8.94156 17.3518C9.44248 12.178 13.7951 8.14225 19.1018 8.14225C24.7456 8.14225 29.3101 12.7068 29.3101 18.3506C29.3101 23.9943 24.7456 28.5589 19.1018 28.5589C16.6481 28.5589 14.3938 27.6833 12.6386 26.2316C12.2118 25.8786 11.5793 25.873 11.1877 26.2647L10.5339 26.9185C10.1434 27.309 10.1411 27.9454 10.5597 28.3056C12.8554 30.2803 15.8307 31.4756 19.1018 31.4756C26.3497 31.4756 32.2268 25.5985 32.2268 18.3506C32.2268 11.1027 26.3497 5.22559 19.1018 5.22559ZM18.6435 12.5173C18.0912 12.5173 17.6435 12.965 17.6435 13.5173V19.2395C17.6435 19.5925 17.8296 19.9194 18.1331 20.0995L23.0262 23.0033C23.5007 23.285 24.1137 23.1289 24.3959 22.6547L24.4235 22.6083C24.706 22.1335 24.55 21.5196 24.075 21.2373L20.3201 19.0058C20.0168 18.8256 19.831 18.499 19.831 18.1462V13.5173C19.831 12.965 19.3833 12.5173 18.831 12.5173H18.6435Z"
        fillOpacity="0.75"
      />
    </svg>
  );
};
