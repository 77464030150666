import { useQueryClient } from "@tanstack/react-query";
import { Button, ButtonProps } from "./ui/button";
import { useNavigate, useParams } from "react-router-dom";
import { forwardRef } from "react";
import { House } from "lucide-react";
import { cn } from "@/utils/cn";

type HomeButtonProps = ButtonProps;

export const HomeButton = forwardRef<HTMLButtonElement, HomeButtonProps>(
  ({ className, ...props }, ref) => {
    const queryClient = useQueryClient();
    const { sessionId } = useParams();
    const navigate = useNavigate();

    const handleClick = () => {
      queryClient.invalidateQueries({
        queryKey: ["messages", sessionId],
        refetchType: "none",
      });
      navigate("/");
    };

    return (
      <Button
        variant="ghost"
        size="icon"
        title="Go to Home" // ShadCN tooltip made motion animations laggy
        onClick={handleClick}
        className={cn("group", className)}
        {...props}
        ref={ref}
      >
        <House
          className={
            "text-white opacity-75 size-7 group-disabled:opacity-50 transition-opacity"
          }
        />
      </Button>
    );
  },
);
