import { Button, ButtonProps } from "@/components/ui/button";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { ChatHistoryMobileContent } from "./chatHistoryMobileContent";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { History } from "../icons/history";
import { queryClient } from "@/configs/queryClient.config";
import { useNavigate, useParams } from "react-router-dom";
import { GenieLogo } from "../icons/logo";
import { X } from "lucide-react";
import { NewChat } from "../icons/newChat";
import { useSidebar } from "@/hooks/useSidebar";
import { motion } from "motion/react";
import { motionConfig } from "@/configs/motion";

type ChatHistoryMobileProps = ButtonProps;

export const ChatHistoryMobile = ({ className }: ChatHistoryMobileProps) => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const { isMobileSidebarOpen, setMobileSidebarOpen } = useSidebar();

  const handleClick = () => {
    setMobileSidebarOpen(false);
    queryClient.invalidateQueries({
      queryKey: ["messages", sessionId],
      refetchType: "none",
    });
    navigate("/");
  };

  return (
    <motion.div
      layout
      transition={motionConfig.transition}
      className="md:hidden"
    >
      <Sheet open={isMobileSidebarOpen} onOpenChange={setMobileSidebarOpen}>
        <SheetTrigger asChild>
          <Button size="icon" variant="ghost" className={className}>
            <History className="size-[33px]" />
            <span className="sr-only">Toggle Sidebar</span>
          </Button>
        </SheetTrigger>
        <SheetContent
          side="top"
          className="inset-y-0 flex flex-col h-full p-0 border-b-0 w-dvw"
          onOpenAutoFocus={(e) => e.preventDefault()}
        >
          <VisuallyHidden.Root>
            <SheetTitle>Sidebar</SheetTitle>
            <SheetDescription> Side Navigation Bar </SheetDescription>
          </VisuallyHidden.Root>
          <div className="absolute top-28 overflow-hidden bg-white -right-28 size-28 blur-[75px]" />
          <div className="absolute top-[calc(100%-20rem)] bg-[#B1102B] -left-16 size-36 blur-[100px]" />
          <div
            className={`h-full shadow-[0px_10px_40px] shadow-primary-dark bg-gradient-to-b from-[#080b16] to-blue`}
          >
            <div className="flex flex-col w-full h-full px-3 pt-10">
              <div
                id="mobile-sidebar-header"
                className="flex flex-row items-center justify-center"
              >
                <GenieLogo className="mr-2 size-12" />
                <h3 className="flex items-center justify-center text-[19.2px] font-semibold text-white">
                  Bulldog Genie
                </h3>
              </div>
              <ChatHistoryMobileContent className="pb-28" />
            </div>
          </div>
          <div className="absolute z-30 bottom-0 left-1/2 -translate-x-1/2 flex flex-row w-fit items-end px-4 py-2 space-x-1 max-w-chat rounded-[18px] bg-primary-dark mb-4 md:mb-6 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25),_0px_13px_27px_-5px_rgba(50,50,93,0.25)]">
            <SheetClose asChild>
              <Button
                className="justify-self-start"
                variant="ghost"
                size="icon"
              >
                <X className="text-white size-8" />
              </Button>
            </SheetClose>
            <SheetClose asChild>
              <Button
                className="justify-self-end"
                variant="ghost"
                size="icon"
                onClick={handleClick}
              >
                <NewChat className="text-white size-8" />
              </Button>
            </SheetClose>
          </div>
        </SheetContent>
      </Sheet>
    </motion.div>
  );
};
