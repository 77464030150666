import { Button } from "@/components/ui/button";
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import { cn } from "@/utils/cn";
import { forwardRef, useEffect, useState } from "react";
import { EllipsisPagination } from "./ellipsisPagination";
import { Clock } from "./icons/clock";
import { ForkAndPlate } from "./icons/forkAndPlate";
import { CampusPointeLogo } from "./icons/campusPointeLogo";
import { BookOpen } from "./icons/bookOpen";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { Computer } from "./icons/computer";
import { Document } from "./icons/document";
import { Glasses } from "./icons/glasses";
import { Truck } from "./icons/truck";
import { DollarSign } from "./icons/dollarSign";
import campusPointe from "@/assets/images/campusPointe.png";
import FSUstatue from "@/assets/images/FSUstatue.jpeg";
import PresidentsBooth from "@/assets/images/presidents-booth.jpeg";
import guyLibrary from "@/assets/images/guy-library.png";
import LearningCenter from "@/assets/images/LearningCenter.png";
import CareerFair from "@/assets/images/CareerFair.png";
import Logo from "@/assets/brand/FresnoStateLogo.png";

export type TSuggestion = {
  id: number;
  lead: string;
  body: string;
};

type SuggestionsProps = {
  handleSuggestionClick: (text: string) => Promise<void>;
  className: string;
};

export const Suggestions = forwardRef<HTMLDivElement, SuggestionsProps>(
  ({ handleSuggestionClick, className }: SuggestionsProps, ref) => {
    const [api, setApi] = useState<CarouselApi>();
    const [current, setCurrent] = useState(0);
    const [count, setCount] = useState(0);

    useEffect(() => {
      if (!api) {
        return;
      }

      // Manage carousel pagination indicator
      setCount(api.scrollSnapList().length);
      setCurrent(api.selectedScrollSnap() + 1);

      api.on("select", () => {
        setCurrent(api.selectedScrollSnap() + 1);
      });
    }, [api]);

    return (
      <div
        className={cn(
          "mb-auto flex h-full w-full max-w-full flex-col items-end space-y-2 overflow-y-auto overflow-x-hidden px-5 pb-32",
          className,
        )}
        ref={ref}
      >
        <div className="sticky top-[-1px] z-40 flex w-full flex-row items-center justify-center py-4">
          <div className="bg-blur absolute -left-5 top-0 z-10 h-full w-dvw bg-gradient-to-b from-transparent to-transparent" />
          <img
            src={Logo}
            loading="lazy"
            className="relative z-20 mr-2 max-h-10"
          />
        </div>
        <div className="max-w-chat mt-auto flex w-full flex-col items-center self-center px-0">
          <h2 className="my-10 text-3xl font-light tracking-wide">
            Welcome!
            <br /> How Can I Help?
          </h2>
          <div className="bg-bento flex h-fit min-h-[25dvh] w-full flex-col space-y-2 overflow-hidden rounded-[20px] p-5 text-sm text-white md:flex-row">
            <img
              src={FSUstatue}
              loading="lazy"
              className="w-full flex-shrink-0 rounded-xl md:w-7/12"
            />
            <p className="text-wrap px-1 text-left font-medium md:self-center md:px-4">
              Welcome to Bulldog Genie. Your friendly AI assistant ready to
              answer your Fresno State questions —anytime, anywhere!
              <span className="underline">
                <a
                  href="https://campusnews.fresnostate.edu/october-14-2024/advancing-together-ai-campus-initiative"
                  target="_blank"
                >
                  <br /> <br />
                  Click here to learn more about Fresno State's AI Campus
                  Initiative
                </a>
              </span>
            </p>
          </div>
          {/* Carousel for mobile */}
          <div className="relative w-full md:hidden">
            <Carousel
              setApi={setApi}
              className="mt-10"
              plugins={[WheelGesturesPlugin()]}
            >
              <CarouselContent className="-ml-8">
                <CarouselItem className="pl-8">
                  <DiningOptionsGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
                <CarouselItem className="pl-8">
                  <CampusResourcesGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
                <CarouselItem className="pl-8">
                  <AcademicSupportGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
                <CarouselItem className="pl-8">
                  <StudentServicesGroup
                    handleSuggestionClick={handleSuggestionClick}
                  />
                </CarouselItem>
              </CarouselContent>
            </Carousel>
            <EllipsisPagination size={count} index={current} className="mt-3" />
          </div>
          {/* Grid Layout for desktop */}
          <div className="relative z-0 my-16 hidden w-full grid-cols-2 grid-rows-2 gap-12 md:grid">
            <DiningOptionsGroup handleSuggestionClick={handleSuggestionClick} />
            <CampusResourcesGroup
              handleSuggestionClick={handleSuggestionClick}
            />
            <AcademicSupportGroup
              handleSuggestionClick={handleSuggestionClick}
            />
            <StudentServicesGroup
              handleSuggestionClick={handleSuggestionClick}
            />
          </div>
          <Button
            variant={"bento"}
            className="bg-bento mt-10 flex h-fit min-h-[25dvh] w-full flex-col space-y-2 overflow-hidden rounded-[20px] p-0"
            onClick={() =>
              handleSuggestionClick(
                "Where can I meet Victor E. and the President?",
              )
            }
          >
            <img src={PresidentsBooth} loading="lazy" className="w-full" />
            <p className="text-wrap p-6 pt-3 text-left">
              Curious about meeting Victor E. or President Saúl
              Jiménez-Sandoval? Click here to discover where to find them and
              join the fun!
            </p>
          </Button>
          <p className="mb-3 mt-20 text-xs">
            Bulldog Genie uses AI. Check for mistakes.
          </p>
        </div>
      </div>
    );
  },
);

function DiningOptionsGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="flex h-72 w-full flex-col items-center justify-center">
      <h3 className="mb-2 ml-1 self-start text-xl font-bold md:self-center">
        Dining Options
      </h3>
      <div className="flex h-full w-full flex-row justify-center gap-4">
        <div className="flex h-full w-full flex-grow flex-col gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What time is the dining hall open?"}
            className="bg-[#2F174B]"
          >
            <Clock className="absolute left-0 top-0 mr-2 size-16 text-[#1C0B31]" />
            <p>Dining Hall Hours</p>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"Where can I get food on campus?"}
            className="bg-[#012D28]"
          >
            <ForkAndPlate className="absolute left-0 top-0 mr-2 size-16 text-[#001E1A]" />
            <p>On Campus Dining</p>
          </SuggestionBox>
        </div>
        <div className="flex h-full w-full flex-grow">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What food can I get at campus pointe?"}
          >
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-60"
              style={{
                backgroundImage: `url(${campusPointe})`,
              }}
            />
            <CampusPointeLogo className="absolute left-0 top-0 z-20 size-16" />
            <p className="relative z-20">Campus Pointe</p>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

function CampusResourcesGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="flex h-72 w-full flex-col items-center justify-center">
      <h3 className="mb-2 ml-1 self-start text-xl font-bold md:self-center">
        Campus Resources
      </h3>
      <div className="flex h-full w-full flex-col justify-center gap-4">
        <div className="flex h-full w-full flex-grow">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What services does the library have to offer?"}
          >
            <div
              className="absolute inset-0 z-0 bg-cover bg-center opacity-60"
              style={{
                backgroundImage: `url(${LearningCenter})`,
                backgroundPosition: "30% 80%",
              }}
            />
            <p className="relative z-10 self-end">
              Student Health and Counseling Center
            </p>
          </SuggestionBox>
        </div>
        <div className="flex h-full w-full flex-grow flex-row gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"How can I reach out to the Learning Center?"}
            className="bg-[#012D28]"
          >
            <BookOpen className="absolute -top-2 left-0 mr-2 size-20 text-[#001E1A]" />
            <p className="relative z-20">Learning Center</p>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"How can I reach out to the Career Development Center?"}
            className="bg-[#2F174B]"
          >
            <Computer className="absolute -top-2 left-0 mr-2 size-20 text-[#1C0B31]" />
            <p className="relative z-20">Career Development Center</p>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

function AcademicSupportGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="flex h-72 w-full flex-col items-center justify-center">
      <h3 className="mb-2 ml-1 self-start text-xl font-bold md:self-center">
        Academic Support
      </h3>
      <div className="flex h-full w-full flex-row justify-center gap-4">
        <div className="flex h-full w-full flex-grow">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What services does the library have to offer?"}
          >
            <div
              className="absolute inset-0 z-0 bg-cover opacity-60"
              style={{
                backgroundImage: `url(${guyLibrary})`,
                backgroundPosition: "30% 50%",
              }}
            />
            <p className="relative z-10 self-end">Library Services</p>
          </SuggestionBox>
        </div>
        <div className="flex h-full w-full flex-grow flex-col gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"Where are some quiet study areas on campus?"}
            className="bg-[#2F174B]"
          >
            <Glasses className="absolute -top-2 left-0 mr-2 size-20 text-[#1C0B31]" />
            <p className="relative z-20">Study Areas</p>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"How can the writing center help me with my coursework?"}
            className="bg-[#012D28]"
          >
            <Document className="absolute -left-1 -top-1 mr-2 size-20 text-[#001E1A]" />
            <p className="relative z-20">Writing Center</p>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

function StudentServicesGroup({
  handleSuggestionClick,
}: {
  handleSuggestionClick: (text: string) => void;
}) {
  return (
    <section className="flex h-72 w-full flex-col items-center justify-center">
      <h3 className="mb-2 ml-1 self-start text-xl font-bold md:self-center">
        Student Services
      </h3>
      <div className="flex h-full w-full flex-col justify-center gap-4">
        <div className="flex h-full w-full flex-grow flex-row gap-4">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"Where can I park on campus?"}
            className="bg-[#2F174B]"
          >
            <Truck className="absolute -top-1 left-0 mr-2 size-20 text-[#1C0B31]" />
            <p className="relative z-20">Commute Info</p>
          </SuggestionBox>
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What is the cost of attendance?"}
            className="bg-[#012D28]"
          >
            <DollarSign className="absolute -left-4 -top-1 mr-2 size-20 text-[#001E1A]" />
            <p className="relative z-20">Financial Aid Guidance</p>
          </SuggestionBox>
        </div>
        <div className="flex h-full w-full flex-col">
          <SuggestionBox
            handleSuggestionClick={handleSuggestionClick}
            prompt={"What resources can I seek to help me advance my career?"}
          >
            <div
              className="absolute inset-0 z-0 bg-cover opacity-60"
              style={{
                backgroundImage: `url('${CareerFair}')`,
                backgroundPosition: "right 20%",
              }}
            />
            <p className="relative z-10 self-end">Career Services</p>
          </SuggestionBox>
        </div>
      </div>
    </section>
  );
}

type SuggestionBoxProps = {
  handleSuggestionClick: (message: string) => void;
  prompt: string;
} & React.HTMLAttributes<HTMLButtonElement>;

export function SuggestionBox({
  className,
  children,
  prompt,
  handleSuggestionClick,
  ...props
}: SuggestionBoxProps) {
  return (
    <Button
      variant="bento"
      className={cn(
        "relative flex h-full w-full flex-col justify-end overflow-hidden text-wrap rounded-[20px] bg-[#071A3D]",
        className,
      )}
      onClick={() => handleSuggestionClick(prompt)}
      {...props}
    >
      {children}
    </Button>
  );
}
