import { forwardRef, useRef, useState } from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ArrowUp } from "lucide-react";
import { ChatHistoryMobile } from "./chatHistory/chatHistoryMobile";
import { motion } from "motion/react";
import { motionConfig } from "@/configs/motion";
import { ChatHistoryDesktopToggle } from "./chatHistory/chatHistoryDesktopToggle";
import { HomeButton } from "./homeButton";

const MotionButton = motion.create(Button, { forwardMotionProps: true });
const MHomeButton = motion.create(HomeButton, { forwardMotionProps: true });
const MChatHistoryToggle = motion.create(ChatHistoryDesktopToggle, {
  forwardMotionProps: true,
});

type InputFieldProps = {
  handleSendMessage: (text: string) => Promise<void>;
  isPending: boolean;
  showHomeButton: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

export const InputField = forwardRef<HTMLDivElement, InputFieldProps>(
  ({ handleSendMessage, isPending, showHomeButton }, ref) => {
    const textAreaElement = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState("");

    const textAreaHeightAdjust = () => {
      if (!textAreaElement.current) {
        return;
      }

      textAreaElement.current.style.height = "auto";
      textAreaElement.current.style.setProperty(
        "height",
        `${textAreaElement.current.scrollHeight}px`,
        "important",
      );
    };

    function handleType(val: string) {
      setText(val);
      textAreaHeightAdjust();
    }

    function handleClick() {
      const msg = text.trim();
      if (msg.length < 1) return;
      handleSendMessage(msg);
      setText("");

      // Resize text box
      if (!textAreaElement.current) return;
      textAreaElement.current.value = "";
      textAreaHeightAdjust();
    }

    return (
      <div
        id="inputfield"
        className="gap-container max-w-chat shadow-input-field relative z-30 mb-4 flex w-full flex-row items-end gap-2 rounded-[18px] bg-[#071A3D] px-3 py-2 md:mb-6"
        ref={ref}
      >
        {showHomeButton && !text && (
          <MHomeButton
            key="Home-Button"
            className="relative mb-1"
            disabled={!!isPending}
            initial={motionConfig.slideRightInitial}
            animate={motionConfig.animate}
            transition={motionConfig.transition}
          />
        )}
        <MChatHistoryToggle
          className="relative mb-1 hidden pb-0.5 md:inline-flex"
          layout
          transition={motionConfig.transition}
        />
        <ChatHistoryMobile className="relative mb-1 pb-0.5" />
        <motion.div
          layout
          transition={motionConfig.transition}
          className="relative flex min-h-0 grow flex-row overflow-hidden rounded-[14px] bg-[#00040C] p-1 py-1 pr-2 focus-within:ring-1 focus-within:ring-white focus-within:ring-offset-0"
        >
          <div className="max-h-52 w-full overflow-y-auto">
            <Textarea
              id="textInput"
              autoFocus
              placeholder="Ask me anything!"
              rows={1}
              className="overflow-y-none block min-h-0 w-full resize-none scroll-smooth border-none bg-transparent text-base outline-none focus-visible:ring-0 focus-visible:ring-offset-0"
              ref={textAreaElement}
              value={text}
              onChange={(e) => handleType(e.target.value)}
              onKeyUp={(e) => {
                if (isPending) return;
                e.key === "Enter" && !e.shiftKey && handleClick();
              }}
            />
          </div>
          {text && (
            <MotionButton
              id="submit"
              size="icon"
              onClick={handleClick}
              disabled={isPending}
              className={
                "text-2xs hover:bg-gray relative mb-1.5 ml-1.5 size-7 min-w-7 self-end rounded-full bg-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-400"
              }
              title="Send Message"
              initial={motionConfig.slideLeftInitial}
              animate={motionConfig.animate}
              transition={motionConfig.transition}
            >
              <ArrowUp className="size-4 text-black" />
            </MotionButton>
          )}
        </motion.div>
      </div>
    );
  },
);
