import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/configs/queryClient.config";
import { RouterProvider } from "react-router-dom";
import { router } from "@/configs/router.config";
import { environment } from "@/configs/environment.config";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { EmbeddedChat } from "@/pages/embedded";
import { useState } from "react";
import { TooltipProvider } from "@radix-ui/react-tooltip";

export default function App() {
  const [isEmbedded] = useState(environment.isEmbedded);

  return (
    <QueryClientProvider client={queryClient}>
      {environment.nodeEnv === "development" ? (
        <ReactQueryDevtools initialIsOpen={false} />
      ) : null}
      {!isEmbedded ? <RouterProvider router={router} /> : null}

      {isEmbedded ? (
        <TooltipProvider>
          <EmbeddedChat />
        </TooltipProvider>
      ) : null}
    </QueryClientProvider>
  );
}
