import { useState } from "react";

export const LoadingText = ({ isLoading }: { isLoading: boolean }) => {
  const loadingMessages = [
    "Crunching the numbers...",
    "Gathering the info...",
    "Finding the best answer...",
    "Piecing it together...",
    "Gathering insights...",
    "Making sure everything's right...",
  ];
  const [loadingMessage, setLoadingMessage] = useState("Thinking...");
  if (isLoading) {
    setTimeout(() => {
      setLoadingMessage(
        loadingMessages[Math.floor(Math.random() * loadingMessages.length)]
      );
    }, 5000);
  }

  return (
    <div className="flex w-full pt-2 place-content-center">
      <div className="relative flex flex-row self-start w-full max-w-chat">
        <div className="flex flex-row min-w-0 py-1 text-left break-words bg-transparent rounded-2xl text-wrap">
          <svg
            className="w-5 h-5 mr-3 text-white animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          {loadingMessage}
        </div>
      </div>
    </div>
  );
};
