import React from "react";

type Props = React.SVGProps<SVGSVGElement>;

export const NewChat = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="currentColor"
      className={className}
    >
      <path
        d="M18 6.33341C24.4312 6.33341 29.6666 11.5688 29.6666 18.0001C29.6666 24.4313 24.4312 29.6667 18 29.6667C16.2791 29.6667 14.5875 29.2876 12.9979 28.5292C12.6041 28.3397 12.1812 28.2522 11.7437 28.2522C11.4666 28.2522 11.1896 28.2959 10.9271 28.3688L6.2604 29.7397L7.63123 25.073C7.8354 24.3876 7.77706 23.6438 7.47081 23.0022C6.71248 21.4126 6.33331 19.7209 6.33331 18.0001C6.33331 11.5688 11.5687 6.33341 18 6.33341ZM18 3.41675C9.94998 3.41675 3.41665 9.95008 3.41665 18.0001C3.41665 20.2459 3.94165 22.3459 4.83123 24.2563L1.95831 34.0417L11.7437 31.1688C13.6541 32.0584 15.7541 32.5834 18 32.5834C26.05 32.5834 32.5833 26.0501 32.5833 18.0001C32.5833 9.95008 26.05 3.41675 18 3.41675Z"
        fill="currentColor"
        fillOpacity="1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4584 12.1667H16.5417V16.5417H12.1667V19.4584H16.5417V23.8334H19.4584V19.4584H23.8334V16.5417H19.4584V12.1667Z"
        fill="currentColor"
        fillOpacity="1"
      />
    </svg>
  );
};
