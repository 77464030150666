import { Button } from "../ui/button";
import { History } from "../icons/history";
import { useSidebar } from "@/hooks/useSidebar";
import { forwardRef } from "react";

type Props = React.HTMLAttributes<HTMLButtonElement>;

export const ChatHistoryDesktopToggle = forwardRef<HTMLButtonElement, Props>(
  ({ className }, ref) => {
    const { isSidebarOpen, setSidebarOpen } = useSidebar();

    return (
      <Button
        size="icon"
        variant="ghost"
        className={className}
        onClick={() => setSidebarOpen(!isSidebarOpen)}
        title="View History"
        ref={ref}
      >
        <History className="w-full h-full" />
        <span className="sr-only">Toggle Sidebar</span>
      </Button>
    );
  },
);
