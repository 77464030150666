import { useLocalStorage } from "./useLocalStorage";
import { useSessionStorage } from "./useSessionStorage";

type UseStorageType<T> = {
  key: string;
  sessionId?: string;
  storageType: "localStorage" | "sessionStorage";
  initialValue: T;
};

export function useStorage<T>({
  key,
  storageType,
  initialValue,
}: UseStorageType<T>) {
  const [local, setLocal] = useLocalStorage<T>({ key, initialValue });
  const [session, setSession] = useSessionStorage<T>({ key, initialValue });

  return storageType === "localStorage"
    ? ([local, setLocal] as const)
    : ([session, setSession] as const);
}
