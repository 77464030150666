import { useState } from "react";

type UseLocalStorageType<T> = {
  key: string;
  initialValue: T;
};

function useLocalStorage<T>({ key, initialValue }: UseLocalStorageType<T>) {
  const [localValue, setLocalValue] = useState<T>(getValue);

  if (JSON.stringify(getValue()) !== JSON.stringify(localValue)) {
    setLocalValue(getValue()); // Force update state when key changes
  }

  function getValue() {
    const storedValue = localStorage.getItem(key);
    try {
      return storedValue ? JSON.parse(storedValue) : initialValue;
    } catch (error) {
      console.error("Error local storage value:", error);
      return initialValue;
    }
  }

  const setValue = (value: T | ((prevValue: T) => T)) => {
    try {
      setLocalValue((prev) => {
        const newValue =
          typeof value === "function" ? (value as (prev: T) => T)(prev) : value; // Support functional updates

        // Update localStorage with the new value
        localStorage.setItem(key, JSON.stringify(newValue));

        return newValue; // Return the updated state
      });
    } catch (error) {
      console.error("Error setting local storage value:", error);
    }
  };

  const removeValue = () => {
    try {
      localStorage.removeItem(key);
      setLocalValue(initialValue);
    } catch (error) {
      console.error("Error removing local storage value:", error);
    }
  };

  return [localValue, setValue, removeValue] as const;
}

export { useLocalStorage };
