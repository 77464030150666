import { TChatMessage, TSystemMessage, TUserMessage } from "@/types/message";
import { parse as parsePartialJSON } from "best-effort-json-parser";

// Convert messages from api to TChatMessage
export function convertMessage(
  message: TUserMessage | TSystemMessage
): TChatMessage {
  if ("user" in message) {
    return {
      id: crypto.randomUUID(),
      owner: "user",
      message: message.user,
    };
  } else {
    return {
      id: crypto.randomUUID(),
      owner: "system",
      message: message.bot.message,
      directions: message.bot.directions,
    };
  }
}

export const parseStreamedResponse = (json: string) => {
  try {
    const data = parsePartialJSON(json);
    return "message" in data ? data.message : "";
  } catch (err) {
    return "";
  }
};
